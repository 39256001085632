/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview Complete helper functions for generating JavaScript for
 *     blocks.  This is the entrypoint for javascript_compressed.js.
 * @suppress {extraRequire}
 */
'use strict';

goog.module('Blockly.JavaScript.all');

const moduleExports = goog.require('Blockly.JavaScript');
goog.require('Blockly.JavaScript.colour');
goog.require('Blockly.JavaScript.lists');
goog.require('Blockly.JavaScript.logic');
goog.require('Blockly.JavaScript.loops');
goog.require('Blockly.JavaScript.math');
goog.require('Blockly.JavaScript.procedures');
goog.require('Blockly.JavaScript.texts');
goog.require('Blockly.JavaScript.variables');
goog.require('Blockly.JavaScript.variablesDynamic');

exports = moduleExports;
